import React from 'react';

// components
import { Button, VStack } from 'avena-components';

// styles
import styles from 'styles/not-found.module.scss';
import Image from 'next/image';
import { useRouter } from 'next/router';

const NotFound = () => {
    const router = useRouter();

    return (
        <VStack className={styles.notFound}>
            <Image src='/404.svg' alt='404' width={500} height={500} />
            <h2>Ooops! Page Not Found</h2>
            <p>This page doesn’t exist or was removed. We suggest you back to home.</p>
            <Button size='large' onClick={() => router.push('/')}>Back to Home</Button>
        </VStack>
    );
};

export default NotFound;
